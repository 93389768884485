<template>
    <v-row> 
        <v-col cols="12">
            <actualizar ref="ActualizarRef" @GetActualizarLista="GetActualizarLista"></actualizar>
        </v-col>

        <v-col cols="12">
            <listar ref="ListarRef" @GetEditar="GetEditar"></listar>
        </v-col>
    </v-row>
</template>
 
<script>
import Listar from './componentes/Listar.vue'
import Actualizar from './componentes/Actualizar.vue';
import { ref } from '@vue/composition-api'
export default {
    components: {
        Listar,
        Actualizar
    },
    setup() {
        const ListarRef = ref(null)
        const ActualizarRef = ref(null)

        const datos = ref({
            "id": -1, 
            "descripcion": "",
            "indActivo": true
        }) 

        const GetActualizarLista = (item) => {
             ListarRef.value.cargar(item)
        }
        const GetEditar = (item) => {
            ActualizarRef.value.CargarEditar(item)
        }

        return {
            ListarRef,
            ActualizarRef,
            datos,
            GetActualizarLista,
            GetEditar
        }
    }
}
</script>
 